<template>
    <div id="drawdown" class="drawdown" v-show="Object.keys(store.tradingDrawDownChart).length && store.tradingDrawDownChart.series && store.tradingDrawDownChart.series.length && store.tradingDrawDownChart.series[0].data.length"></div>
     <Nodata v-if="!store.loader && Object.keys(store.tradingDrawDownChart).length && store.tradingDrawDownChart.series && store.tradingDrawDownChart.series.length && store.tradingDrawDownChart.series[0].data.length == 0"></Nodata>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            drawdownJSon : {
                'DRAWDOWN_DAILY_MONEY' : this.$t('leader_profile.dailyDrawdown'),
                'CLOSED_PROFIT_MONEY' : this.$t('leader_profile.closedTradesOnly'),
                'TOTAL_PROFIT_MONEY' : this.$t('leader_profile.profitDrawdown'),
                'DRAWDOWN_DAILY' : this.$t('leader_profile.dailyDrawdown'),
                'CLOSED_PROFIT' : this.$t('leader_profile.closedTradesOnly'),
                'TOTAL_PROFIT' : this.$t('leader_profile.profitDrawdown')
            }
        }
    },
    watch : {
        '$route.params.id'() {
            if(this.$route.params.id && this.$parent.$parent.tradingprofitTab){
                this.getTrendingDrawDownChartData()
            }
        },
        '$route.query.t'() {
            if(this.$route.params.id && this.$parent.$parent.tradingprofitTab){
                this.getTrendingDrawDownChartData()
            }
        },
        '$parent.$parent.tradingprofitTab'(){
            if(this.$route.params.id && this.$parent.$parent.tradingprofitTab){
                this.getTrendingDrawDownChartData()
            }
        }
    },
    methods: {
        drawdownChart(){
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'drawdown');
            d?.dispose();
            var root = am5.Root.new("drawdown");
            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root)
            ]);
            root.dateFormatter.setAll({
                dateFormat: "dd MMM yyyy",
                dateFields: ["valueX"]
            });
            root.numberFormatter.setAll({
              numberFormat: "#a",
              smallNumberThreshold: 0.001
            });


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: false,
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                paddingLeft: 0,
                paddingRight: 0,
            }));

            chart.get("colors").set("colors", [
                am5.color(0x50b432),
                am5.color(0x4572a7),
                am5.color(0xff7200),
            ]);

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
              behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
              maxDeviation: 0,
              baseInterval: {
                timeUnit: "day",
                count: 1
              },
              renderer: am5xy.AxisRendererX.new(root, {}),
              tooltip: am5.Tooltip.new(root, {})
            }));

            var label = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label );

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
              extraMin: 0.2,
              renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            var label1 = am5.Label.new(root, {
                rotation: -90,
                text: this.$parent.$parent.tradingprofitTab=='Pips' ? 'Pips' : this.store.traderDetail.trader.stats.profile.baseCurrencyName,
                fill: am5.color(0x666666),
                y: am5.p50,
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );
             let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });
            
            var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                // layout: root.horizontalLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });
            this.store.tradingDrawDownChart.series.reverse().forEach((val) => {
                if(val.type == 'TOTAL_PROFIT_MONEY' || val.type == 'CLOSED_PROFIT_MONEY' || val.type == 'TOTAL_PROFIT' || val.type == 'CLOSED_PROFIT'){
                    var series = chart.series.push(am5xy.LineSeries.new(root, {
                    legendLabelText: this.drawdownJSon[val.type],
                      name: "Series",
                      xAxis: xAxis,
                      yAxis: yAxis,
                      valueYField: "y",
                      valueXField: "x",
                      tooltip: am5.Tooltip.new(root, {
                       labelText: (val.type == 'TOTAL_PROFIT_MONEY' || val.type == 'TOTAL_PROFIT') ? "[bold]Live Profit[/]: {valueY} \n [bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}" : (val.type == 'CLOSED_PROFIT_MONEY' || val.type == 'CLOSED_PROFIT') ? "[bold]Closed Trades Only[/]: {valueY} \n [bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}" : "",
                      })
                    }));
                    series.strokes.template.setAll({
                        strokeWidth: 2
                    });
                    series.data.setAll(val.data);
                    //legend.data.unshift(series);
                    
                    series.hide();
                    
                    // series.appear(1000);
                }else{
                    // var volumeAxisRenderer = am5xy.AxisRendererY.new(root, {});
                    // volumeAxisRenderer.grid.template.set("forceHidden", true);
                    // volumeAxisRenderer.labels.template.set("forceHidden", true);

                    // var volumeAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    //   height: am5.percent(25),
                    //   y: am5.percent(100),
                    //   centerY: am5.percent(100),
                    //   panY:false,
                    //   renderer: volumeAxisRenderer
                    // }));


                    // Add series
                    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
                    var volumeSeries = chart.series.unshift(am5xy.ColumnSeries.new(root, {
                        legendLabelText: this.drawdownJSon[val.type],
                      name: "Volume Series",
                      xAxis: xAxis,
                      yAxis: yAxis,
                      valueYField: "y",
                      valueXField: "x",
                      tooltip: am5.Tooltip.new(root, {
                        labelText: "[bold]Live Daily Drawdown[/]: {valueY} \n [bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}",
                      })
                    }));

                    volumeSeries.columns.template.setAll({ width: am5.percent(40) })
                    volumeSeries.data.setAll(val.data);
                    //legend.data.unshift(volumeSeries);
                }
            })
             xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 12,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
            legend.data.setAll(chart.series.values);
            chart.appear(1000, 100);
        },
        getTrendingDrawDownChartData(){
            let formData = {};
            let type = '';
            if(this.$parent.$parent.tradingprofitTab == this.store.traderDetail.trader.stats.profile.baseCurrencyName){
                type = 'TOTAL_PROFIT_MONEY,CLOSED_PROFIT_MONEY,DRAWDOWN_DAILY_MONEY'
            }else{
                type = 'TOTAL_PROFIT,CLOSED_PROFIT,DRAWDOWN_DAILY'
            }
            this.store.callTrendingDrawDownChartData(formData,true,this.$route.params.id,this.$route.query.t,type).then(() =>{
                 if(Object.keys(this.store.tradingDrawDownChart).length && this.store.tradingDrawDownChart.series && this.store.tradingDrawDownChart.series.length > 0)
                    am5.ready(() => {
                        this.drawdownChart()
                    });
            })
        }
    },
    mounted() {
        this.getTrendingDrawDownChartData()
    },
}
</script>
<style>
    .drawdown{
        height: 400px;
        width: 100%;
    }
</style>