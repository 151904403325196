<template>
    
    <div class="d-flex justify-content-between">
       
        <ul id="tabs-nav" class="d-flex align-items-center px-0 mb-0">
            <li><a class="toogleUP" :class="[{'active' : tradingprofitTab == 'ROI'}]" href="javascript:void(0)" @click="CharttabFilters('ROI');">{{$t('leader_profile.roi')}}</a></li>
            <li><a class="toogleUP" :class="[{'active' : tradingprofitTab == store.traderDetail.trader.stats.profile.baseCurrencyName}]" href="javascript:void(0)" @click="CharttabFilters(store.traderDetail.trader.stats.profile.baseCurrencyName);">{{store.traderDetail.trader.stats.profile.baseCurrencyName}}</a></li>
            <li><a class="toogleUP" :class="[{'active' : tradingprofitTab == 'Pips'}]" href="javascript:void(0)" @click="CharttabFilters('Pips');">{{$t('leader_profile.pips')}}</a></li>
        </ul>
            <!-- <p class="mb-0 f-14">Timeframe: <strong>{{$parent.dateDropSelected.value}}</strong></p> -->
            <div class="position-relative advancedMenu dateperiod border" v-if="tradingprofitTab != 'ROI'">
                <a href="javascript:void(0)" class="f-14 selectDropdown min-auto bg-white d-flex align-items-center justify-content-between" @click="openTrades=!openTrades">
                        {{traders == 2 ? $t('leader_profile.additionalInfo') : $t('leader_profile.additionalInfo')}}<i class="fa fa-caret-down ms-1" aria-hidden="true"></i>
                </a>
                <ul class="dropdown_menu_animated week py-2" :class="[{'show':openTrades}]">
                    <li>
                        <a @click="TradersActive()" :class="[{'active':traders == 2}]" href="javascript:void(0)">{{traders == 2 ? $t('leader_profile.additionalInfo') : $t('leader_profile.openTrades')}}</a>
                    </li>
                </ul>
            </div>
    </div>
    <div id="traderprofitChart" class="profit" v-show="Object.keys(store.tradingProfitChart).length && store.tradingProfitChart.series && store.tradingProfitChart.series.length && store.tradingProfitChart.series[0].data.length">
    </div>
    <Nodata v-if="!(Object.keys(store.tradingProfitChart).length && store.tradingProfitChart.series?.length && store.tradingProfitChart.series[0].data.length)"></Nodata>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            openTrades : false,
            traders : 1,
            profitJson : {
                'USD' : 'USD',
                'Pips' : 'pips',
                'ROI' : '%'
            },
            tradingprofitTab : ''
        }
    },
    watch : {
        '$route.params.id'() {
            if(this.tradingprofitTab){
                this.getTradingProfitChartData()
            }
        },
        '$route.query.t'() {
            if(this.tradingprofitTab){
                this.getTradingProfitChartData()
            }
        },
        // 'tradingprofitTab'(){
        //     this.getTradingProfitChartData()
        // },
        // 'store.traderDetail'() {
        //     if (Object.keys(this.store.traderDetail).length > 0) {
        //         //this.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName
        //         this.$parent.callCurrencyPairsList()
        //     }
        // },
    },
    methods: {
         CharttabFilters(type){
            this.tradingprofitTab = type
            this.openTrades = false;
            this.traders = 1
            this.getTradingProfitChartData()
        },
        TradersActive(){
            if(this.traders == 1){
                this.traders = 2
            }else{
                this.traders =  1
            }
            this.openTrades = false
            this.getTradingProfitChartData()

        },
        drawprofitChartWithOpenTraders(){ 
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'traderprofitChart');
            d?.dispose();
            let root = am5.Root.new('traderprofitChart');
            root.setThemes([
                am5themes_Animated.new(root),
                // am5themes_Dark.new(root),
                am5themes_Responsive.new(root)
            ]);

            root.dateFormatter.setAll({
                dateFormat: "yyyy",
                dateFields: ["valueX"]
            });
            root.numberFormatter.setAll({
              numberFormat: "#a",
              smallNumberThreshold: 0.001
            });
            // Create drawprofitChart           
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: true,
                paddingRight: 0,
                paddingLeft : 0,
            }));

            // Create axes
            let xAxis = chart.xAxes.push(am5xy.GaplessDateAxis.new(root, {
                groupData: false,
                baseInterval: {
                    timeUnit: "day",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, { 
                    minGridDistance: 70
                }),
                startLocation: 1,
                endLocation: 1
                //tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.get("dateFormats")["day"] = "MM/dd";
            xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });

            chart.get("colors").set("colors", [
                am5.color(0xffdfc3),
                am5.color(0xff7200),
            ]);

             var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                clickTarget: "none",
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });
            // Create axes
            let xAxis1 = chart.xAxes.push(am5xy.DateAxis.new(root, {
                visible : false,
                groupData: false,
                baseInterval: {
                    timeUnit: "date",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, { 
                    minGridDistance: 70
                }),
            }));

            xAxis1.get("dateFormats")["day"] = "MM/dd";
            xAxis1.get("periodChangeDateFormats")["day"] = "MMMM";

            this.chartDataProfit().forEach(key=>{
                // Add series
                var series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
                    legendLabelText: this.tradingprofitTab=='Pips' ? key.name +' (Pips)' : (this.tradingprofitTab=='ROI' ? key.name +' (%)' : key.name +' ('+this.store.traderDetail.trader.stats.profile.baseCurrencyName+')'),
                    minBulletDistance: 1,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{valueY}",
                    })
                }));
                series.bullets.push(function() {
                var circle = am5.Circle.new(root, {
                    radius: 0,
                    fill: am5.color(0x2F5F8F),
                    stroke: root.interfaceColors.get("background"),
                    strokeWidth: 2,
                    tooltipText: "{title}",
                    showTooltipOn: "always",
                    tooltip: am5.Tooltip.new(root, {})
                });
                return am5.Bullet.new(root, {
                    sprite: circle,
                    fontSize: 12
                });
                });
    
                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
                    stops: [{opacity: 0.7}, {opacity: 0.1}]
                }));
                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["y"]
                });
                var data = key.data
                series.data.setAll(data);
                legend.data.push(series);
                // Make stuff animate on load
                series.appear(1000);
            })
            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                // xAxis: xAxis,
                behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            //chart.zoomOutButton.set("forceHidden", true);

            xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 10,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
             var label2 = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );
            var label1 = am5.Label.new(root, {
                rotation: -90,
                text: this.tradingprofitTab=='Pips' ? 'Profit (Pips)' : (this.tradingprofitTab=='ROI' ? 'Profit (%)' : 'Profit ('+this.store.traderDetail.trader.stats.profile.baseCurrencyName+')'),
                fill: am5.color(0x666666),
                y: am5.p50,
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );

            // series1.appear(1000);
            chart.appear(1000, 100);
        },
        drawprofitChart(){ 
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'traderprofitChart');
            d?.dispose();
            let root = am5.Root.new('traderprofitChart');

            root.setThemes([
                am5themes_Animated.new(root),
                // am5themes_Dark.new(root),
                am5themes_Responsive.new(root)
            ]);

            root.dateFormatter.setAll({
                dateFormat: "dd MMM yyyy",
                dateFields: ["valueX"]
            });
            root.numberFormatter.setAll({
              numberFormat: "#.##",
            //   smallNumberThreshold: 0.001
            });
            // Create drawprofitChart           
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: true,
                paddingRight: 0,
                paddingLeft : 0,
            }));

            // Create axes
            let xAxis = chart.xAxes.push(am5xy.GaplessDateAxis.new(root, {
                groupData: true,
                baseInterval: {
                    timeUnit: "day",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, { 
                    minGridDistance: 50
                }),
                startLocation: 1,
                endLocation: 1
                //tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.get("dateFormats")["day"] = "MM/dd";
            xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    strictMinMax: true,
                    renderer: am5xy.AxisRendererY.new(root, {})
                })
            );
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1
            });
           
            //let range = yAxis.createAxisRange(rangeDataItem);
            if(this.chartDataProfit().length > 1){
                chart.get("colors").set("colors", [
                    am5.color(0xffdfc3),
                    am5.color(0xff7200),
                ]);
            } else {
                chart.get("colors").set("colors", [
                    am5.color(0xff7200),
                ]);
            }

             var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.horizontalLayout,
                useDefaultMarker: true,
                clickTarget: "none",
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });

            this.chartDataProfit().forEach(key=>{
                // Add series
                var series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
                    legendLabelText: this.tradingprofitTab=='Pips' ? key.name +' (Pips)' : (this.tradingprofitTab=='ROI' ? key.name +' (%)' : key.name +' ('+this.store.traderDetail.trader.stats.profile.baseCurrencyName+')'),
                    minBulletDistance: 10,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: (this.tradingprofitTab=='ROI') ? "[bold]Live Profit(%)[/]: {valueY} \n [bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}" : (this.tradingprofitTab=='Pips') ? "[bold]Live Profit(pips)[/]: {valueY} \n [bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}" : "[bold]Live Profit[/]: {valueY} \n [bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}",
                    })
                }));
                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
                    stops: [{
                        opacity: 0.7
                    }, {
                        opacity: 0.1
                    }]
                }));

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });
        
                // Set data
                var data = key.data
                series.data.setAll(data);
                legend.data.push(series);

                // Make stuff animate on load
                series.appear(1000);
            })
            
            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                // xAxis: xAxis,
                behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

             xAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#666666")),
                fontSize: 10,
            });
            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
             var label2 = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );
            var label1 = am5.Label.new(root, {
                rotation: -90,
                text: this.tradingprofitTab =='Pips' ? 'Profit (Pips)' : (this.tradingprofitTab =='ROI' ? 'Profit (%)' : 'Profit ('+this.store.traderDetail.trader.stats.profile.baseCurrencyName+')'),
                fill: am5.color(0x666666),
                y: am5.p50,
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );
            
            chart.appear(1000, 100);

            var previousBulletSprites = [];
            cursor.events.on("cursormoved", cursorMoved);

            function cursorMoved() {
              for(var i = 0; i < previousBulletSprites.length; i++) {
                previousBulletSprites[i].unhover();
              }
              previousBulletSprites = [];
              chart.series.each(function(series) {
                var dataItem = series.get("tooltip").dataItem;
                if (dataItem && dataItem.bullets) {
                  var bulletSprite = dataItem.bullets[0].get("sprite");
                  bulletSprite.hover();
                  previousBulletSprites.push(bulletSprite);
                }
              });
            }
        },
        getTradingProfitChartData(){
            if(this.$route.query.t){   
                let formData = {};
                if(Object.keys(this.$parent.currencyFilter).length && Object.keys(this.store.currencyPairs).length){
                    let data = [];
                    for(var key in this.$parent.currencyFilter){
                        this.store.currencyPairs.forEach(val =>{
                            if(this.$parent.currencyFilter[key] != '' && val.name == this.$parent.currencyFilter[key]){
                               data.push(val.id)
                            }
                        })
                    }
                    if(data.length){
                        formData['cid'] = data.join(',')
                    }
                }
                if(this.traders == 2){
                    formData['openTrades'] = 1 
                }else{
                    formData['openTrades'] = 2
                }
                this.store.callTradingProfitChartData(formData,true,this.$route.params.id,this.$route.query.t,this.tradingprofitTab).then(() =>{
                    if(Object.keys(this.store.tradingProfitChart).length && this.store.tradingProfitChart.series && this.store.tradingProfitChart.series.length > 0 && this.store.tradingProfitChart.series[0].data && this.store.tradingProfitChart.series[0].data.length )
                    am5.ready(() => {
                        if(this.traders == 1){
                            this.drawprofitChart()
                        }else{
                            this.drawprofitChartWithOpenTraders()
                        }
                        this.chartDataProfit()
                    });
                })
            }
        },
        chartDataProfit(){
            if(this.store.tradingProfitChart.series[0].endDateOfImportedTradeHistory){
                const isLargeNumber = (ind) => ind.x > this.store.tradingProfitChart.series[0].endDateOfImportedTradeHistory;
                var index = this.store.tradingProfitChart.series[0].data.findIndex(isLargeNumber)
                var arrays1 = this.store.tradingProfitChart.series[0].data.slice(0,index)
                var arrays2 = [];
                var finaldat = []
                if(arrays1.length){
                    arrays2 = this.store.tradingProfitChart.series[0].data.slice(index-1);
                    finaldat.push({data:arrays1,name:'Imported history Profit'})
                    finaldat.push({data:arrays2,name:'Live Profit'})
                }else{
                     arrays2 = this.store.tradingProfitChart.series[0].data.slice(index);
                     finaldat.push({data:this.store.tradingProfitChart.series[0].data,name:'Live Profit'})
                }
                return finaldat
            } else {
                var finalsingle = []
                finalsingle.push({data:this.store.tradingProfitChart.series[0].data,name:'Live Profit'})
                return finalsingle
            }
        },
    },
}
</script>
<style>
    .profit{
        height: 400px;
        width: 100%;
    }
</style>